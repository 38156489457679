import instagram from '../../../static/svgs/instagram-icon.svg'
import instagramC from '../../../static/svgs/instagram-c-icon.svg'
import twitterSP from '../../../static/svgs/twitter-simple.svg'
import twitterC from '../../../static/svgs/twitter-c-icon.svg'
import youtubeC from '../../../static/svgs/youtube-c-icon.svg'
import facebookC from '../../../static/svgs/facebook-c-icon.svg'
import facebookFilled from '../../../static/svgs/facebook-filled-icon.svg'
import threadsFilled from  '../../../static/svgs/threads-filled-icon.svg'
import youtubeFilled from '../../../static/svgs/youtube-filled-icon.svg'
import twitterFilled from '../../../static/svgs/twitter-filled-icon.svg'
import instagramFilled from '../../../static/svgs/instagram-filled-icon.svg'
import close from '../../../static/svgs/Close-icon.svg'
import burger from '../../../static/svgs/Burger-icon.svg'
import Prev from '../../../static/svgs/prev-icon.svg'
import Next from '../../../static/svgs/next-icon.svg'
import narrowDown from '../../../static/svgs/down-arrow.svg'
import AppIcon from '../../../static/svgs/app-c-icon.svg'
import linkedinFilled from '../../../static/svgs/linkedin-filled-icon.svg'
import icon_chat from '../../../static/svgs/icon_chat.svg'
import icon_direction from '../../../static/svgs/icon_direction.svg'
import icon_email from '../../../static/svgs/icon_email.svg'
import icon_fb from '../../../static/svgs/icon_fb.svg'
import icon_fb2 from '../../../static/svgs/icon_fb2.svg'
import icon_instagram from '../../../static/svgs/icon_instagram.svg'
import icon_instagram2 from '../../../static/svgs/icon_instagram2.svg'
import icon_instagram3 from '../../../static/svgs/icon_instagram3.svg'
import icon_letdoit from '../../../static/svgs/icon_letdoit.svg'
import icon_map from '../../../static/svgs/icon_map.svg'
import icon_plus from '../../../static/svgs/icon_plus.svg'
import icon_plus_3 from '../../../static/svgs/icon_plus_3.svg'
import icon_plus_4 from '../../../static/svgs/icon_plus_4.svg'
import icon_equal from '../../../static/svgs/icon_equal.svg'
import icon_plus_circle from '../../../static/svgs/icon_plus_circle.svg'
import icon_minus_circle from '../../../static/svgs/icon_minus_circle.svg'
import icon_ready from '../../../static/svgs/icon_ready.svg'
import icon_store from '../../../static/svgs/icon_store.svg'
import icon_youtube from '../../../static/svgs/icon_youtube.svg'
import icon_youtube2 from '../../../static/svgs/icon_youtube2.svg'
import icon_phone from '../../../static/svgs/icon_phone.svg'
import icon_angle_down from '../../../static/svgs/icon_angle-down.svg'
import icon_linkedin from '../../../static/svgs/icon-linkedin.svg'
import icon_pinterest from '../../../static/svgs/icon-pinterest.svg'
import icon_twitter from '../../../static/svgs/icon-twitter.svg'
import icon_threads from  '../../../static/svgs/icon-threads.svg'
import icon_whatsapp from  '../../../static/svgs/icon-whatsapp.svg'

export const iconConfigs = [
  {
    key: 1,
    filename: instagram,
    viewbox: '0 0 37 37'
  },
  {
    key: 2,
    filename: instagramC,
    viewbox: '0 0 37 37'
  },
  {
    key: 3,
    filename: twitterSP,
    viewbox: '0 0 37 37'
  },
  {
    key: 4,
    filename: twitterC,
    viewbox: '0 0 37 37'
  },
  {
    key: 5,
    filename: facebookC,
    viewbox: '0 0 37 37'
  },
  {
    key: 6,
    filename: youtubeC,
    viewbox: '0 0 37 37'
  },

  {
    key: 7,
    filename: facebookFilled,
    viewbox: '0 0 37 37'
  },
  {
    key: 8,
    filename: youtubeFilled,
    viewbox: '0 0 37 37'
  },
  {
    key: 9,
    filename: twitterFilled,
    viewbox: '0 0 37 37'
  },
  {
    key: 10,
    filename: instagramFilled,
    viewbox: '0 0 37 37'
  },
  {
    key: 11,
    filename: close,
    viewbox: '0 0 37 37'
  },
  {
    key: 12,
    filename: burger,
    viewbox: '0 0 37 37'
  },
  {
    key: 13,
    filename: Prev,
    viewbox: '0 0 37 37'
  },
  {
    key: 14,
    filename: Next,
    viewbox: '0 0 37 37'
  },
  {
    key: 15,
    filename: narrowDown,
    viewbox: '0 0 37 37'
  },
  {
    key: 16,
    filename: AppIcon,
    viewbox: '0 0 37 37'
  },
  {
    key: 17,
    filename: linkedinFilled,
    viewbox: '0 0 40 40'
  },
  {
    key: 18,
    filename: icon_chat,
    viewbox: '0 0 37 37'
  },
  {
    key: 19,
    filename: icon_direction,
    viewbox: '0 0 37 37'
  },
  {
    key: 20,
    filename: icon_email,
    viewbox: '0 0 37 37'
  },
  {
    key: 21,
    filename: icon_fb,
    viewbox: '0 0 37 37'
  },
  {
    key: 22,
    filename: icon_fb2,
    viewbox: '0 0 37 37'
  },
  {
    key: 23,
    filename: icon_instagram,
    viewbox: '0 0 37 37'
  },

  {
    key: 24,
    filename: icon_instagram2,
    viewbox: '0 0 37 37'
  },
  {
    key: 25,
    filename: icon_letdoit,
    viewbox: '0 0 37 37'
  },
  {
    key: 26,
    filename: icon_map,
    viewbox: '0 0 37 37'
  },
  {
    key: 27,
    filename: icon_plus,
    viewbox: '0 0 37 37'
  },
  {
    key: 28,
    filename: icon_plus_circle,
    viewbox: '0 0 37 37'
  },
  {
    key: 29,
    filename: icon_ready,
    viewbox: '0 0 37 37'
  },
  {
    key: 30,
    filename: icon_store,
    viewbox: '0 0 37 37'
  },
  {
    key: 31,
    filename: icon_youtube,
    viewbox: '0 0 37 37'
  },
  {
    key: 32,
    filename: icon_youtube2,
    viewbox: '0 0 37 37'
  },
  {
    key: 33,
    filename: icon_minus_circle,
    viewbox: '0 0 37 37'
  },
  {
    key: 34,
    filename: icon_phone,
    viewbox: '0 0 37 37'
  },
  {
    key: 35,
    filename: icon_equal,
    viewbox: '0 0 37 37'
  },
  {
    key: 36,
    filename: icon_plus_3,
    viewbox: '0 0 37 37'
  },
  {
    key: 37,
    filename: icon_plus_4,
    viewbox: '0 0 37 37'
  },
  {
    key: 38,
    filename: icon_angle_down,
    viewbox: '0 0 37 37'
  },
  {
    key: 39,
    filename: icon_linkedin,
    viewbox: '0 0 37 37'
  },
  {
    key: 40,
    filename: icon_pinterest,
    viewbox: '0 0 37 37'
  },
  {
    key: 41,
    filename: icon_twitter,
    viewbox: '0 0 37 37'
  },
  {
    key: 42,
    filename: icon_instagram3,
    viewbox: '0 0 18 18'
  },
  {
    key: 43,
    filename: threadsFilled,
    viewbox: '0 0 37 37'
  },
  {
    key: 44,
    filename: icon_threads,
    viewbox: '0 0 37 37'
  },
  {
    key: 45,
    filename: icon_whatsapp,
    viewbox: '0 0 37 37'
  },
]
