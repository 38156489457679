import { iconConfigs } from './iconConfig'
import { StyledSVGIcon } from './Icon.styles'
import Head from 'next/head'

interface SVGProps {
  key?: number
  filename: string
  viewbox?: string
}

export enum IconEnums {
  'instagram' = 1,
  'instagram-c' = 2,
  'twitter-simple' = 3,
  'twitter-c' = 4,
  'facebook-c' = 5,
  'youtube-c' = 6,
  'facebook-filled' = 7,
  'youtube-filled' = 8,
  'twitter-filled' = 9,
  'instagram-filled' = 10,
  'close' = 11,
  'burger' = 12,
  'prev' = 13,
  'next' = 14,
  'narrow-down' = 15,
  'plus-app' = 16,
  'linkedin-filled' = 17,
  'icon-chat' = 18,
  'icon-direction' = 19,
  'icon-email' = 20,
  'icon-fb' = 21,
  'icon-fb2' = 22,
  'icon-instagram' = 23,
  'icon-instagram2' = 24,
  'icon-instagram3' = 42,
  'icon-letdoit' = 25,
  'icon-map' = 26,
  'icon-plus' = 27,
  'icon-plus-circle' = 28,
  'icon-ready' = 29,
  'icon-store' = 30,
  'icon-youtube' = 31,
  'icon-youtube2' = 32,
  'icon-minus' = 33,
  'icon-phone' = 34,
  'icon-equal' = 35,
  'icon-plus-3' = 36,
  'icon-plus-4' = 37,
  'icon-angle-down' = 38,
  'icon-linkedin' = 39,
  'icon-pinterest' = 40,
  'icon-twitter' = 41,
  'threads-filled' = 43,
  'icon-threads' = 44,
  'icon-whatsapp' = 45,
}

export interface SVGIconProps {
  name: IconEnums
  className?: string
  options?: SVGProps
  clickable?: boolean
  preload?: boolean
}

export const SVGIcon = (props: SVGIconProps) => {
  const { name, className, options, clickable, preload } = props
  let opts = options
  if (!opts && name) {
    opts = iconConfigs.find(s => s.key == name)
  }
  return (
    <>
      {preload && (
        <Head>
          <link
            rel='preload'
            href={opts?.filename}
            as='image'
            type='image/svg+xml'
          />
        </Head>
      )}
      <StyledSVGIcon
        clickable={clickable}
        src={`${opts?.filename}`}
        className={`${className && className}`}
        wrapper='span'
      />
    </>
  )
}

export default SVGIcon
