import styled from 'styled-components'
import tw from 'twin.macro'

export const EnquiryFormRow = styled.div<{ justify?: 'start' | 'center' | 'end' }>`
  ${tw`flex flex-col lg:flex-row lg:justify-around lg:w-full`}

  ${({ justify }) => {
    switch (justify) {
      case 'start':
        return tw`lg:justify-start`
      case 'center':
        return tw`lg:justify-center`
      case 'end':
        return tw`lg:justify-end`
    }
  }}

`
export const EnquiryForm = styled.form`
  ${tw`m-4 lg:flex lg:flex-wrap lg:justify-start`}
`
