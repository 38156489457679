import { FormikProps } from 'formik'
import { FormFieldProps } from '../../services/api/types'
import { CheckBox } from '../base'

interface PrivacyFieldProps {
  field: Partial<FormFieldProps>
  formik: FormikProps<any>
  isYouFitnessTemplate?: boolean
}

const PrivacyField: React.FC<PrivacyFieldProps> = ({
  field,
  formik,
  isYouFitnessTemplate
}) => {
  return (
    <CheckBox
      name={field.name}
      type='checkbox'
      label={
        <span
          style={{
            color: isYouFitnessTemplate ? 'white' : ''
          }}
        >
          I agree to the{' '}
          <a
            style={{
              textDecoration: 'underline',
              color: isYouFitnessTemplate ? 'white' : ''
            }}
            href={field.label || '/privacy-policy/'}
            target='_blank'
          >
            privacy policy
          </a>{' '}
          referenced on the Plus Fitness website
        </span>
      }
      color='black'
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
    />
  )
}

export default PrivacyField
